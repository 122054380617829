<template>
  <div class="col-md-12">
    <div class="card card-container">
      <form @submit.prevent="assignToProduct">
        <label for="product_id">select a product</label>
        <select v-model="product_id" name="product_id" class="form-select">
          <option v-for="(k, product) in products.data" v-bind:key="product" :value="k.id">{{ k.name }}</option>
        </select>
        <label for="provider_id">select a provider</label>
        <select v-model="provider_id" class="form-select" v-if="providers">
            <option  v-for="(k, provider) in providers.data" v-bind:key="provider" :value="k.id">{{ k.name }}</option>
        </select>
        <button type="submit">submit</button>
    </form>

    <div v-if="message" class="alert" :class="successful ? 'alert-success' : 'alert-danger'">
        {{ message }}
      </div>
    </div>

  </div>
</template>

<script>
// import * as yup from "yup";

import ProviderService from "../services/provider.service";
import ProductsService from "../services/product.service";

export default {
  name: "Assign provider to product",
  components: {
  },
  methods: {
    assignToProduct() {
      console.log(this.provider_id, this.product_id);
      this.formData = {
        'provider_id': this.provider_id,
        'product_id': this.product_id,
      };
      ProviderService.assignToProduct(this.formData).then(
          (response) => {
            this.message = response.data.message;
            this.successful = true;
            this.loading = false;
            this.provider_id = null;
            this.product_id = null;
          },
          (error) => {
            this.message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
            this.successful = false;
            this.loading = false;
          }
        );
    },
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },

  data() {
    return {
      successful: false,
      loading: false,
      message: "",
      provider_id: "",
      product_id: "",
      formData: {},
      providers: "",
      products: "",
      // schema,
    };
  },
  mounted() {
    if (this.loggedIn) {
      // this.$router.push("/profile");
    }

    ProviderService.getProviders().then(
      (response) => {
        this.providers = response.data;
        console.log(this.providers);
      },
      (error) => {
        this.content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );

    ProductsService.getProducts().then(
      (response) => {
        this.products = response.data;
        console.log(this.products);
      },
      (error) => {
        this.content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  },
};

</script>

<style scoped>
</style>

